/* eslint-disable no-inner-declarations */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import ActivityIndicator from './ActivityIndicator';

const { maps: Maps } = window.google;

function MapView({
  center, children = [], drawPolylines, isVisible,
}) {
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  const instances = useRef([]);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (mapRef.current) {
      async function initMap() {
        const { lat = -34.397, lng = 150.644, zoom = 15 } = center;
        const { Map } = await Maps.importLibrary('maps');
        const { event } = await Maps.importLibrary('core');

        const gMap = new Map(mapRef.current, {
          center: { lat, lng },
          zoom,
          mapId: 'dd14c0294ccd40cb',
          disableDefaultUI: true,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          //   optimized: false,
        });

        event.addListener(gMap, 'tilesloaded', () => {
          setMapLoaded(true);
        });

        setMap(gMap);
        // return () => {
        //     event.clearInstanceListeners(gMap);
        // };
      }

      initMap();
    }
  }, [mapRef]);

  useEffect(() => {
    if (map) {
      if (isVisible) setMapLoaded(false);
      async function initInstances() {
        const { Polyline } = await Maps.importLibrary('maps');
        const { LatLngBounds } = await Maps.importLibrary('core');
        const { AdvancedMarkerElement } = await Maps.importLibrary('marker');

        if (instances.current?.length) {
          instances.current.forEach((instance) => instance.setMap(null));
          instances.current = [];
        }

        if (drawPolylines && children.length > 1) {
          const polyline = new Polyline({
            path: children.map(({ props }) => ({
              lat: props.lat,
              lng: props.lng,
            })),
            strokeColor: '#fff',
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: 'M 0,-1 0,1',
                  strokeOpacity: 1,
                  scale: 4,
                },
                offset: '0',
                repeat: '20px',
              },
            ],
          });
          polyline.setMap(map);
          instances.current.push(polyline);
        }

        if (children.length) {
          const bounds = new LatLngBounds();
          children.forEach((child) => {
            const { lat, lng } = child.props;
            const markerContent = document.createElement('div');
            const root = ReactDOM.createRoot(markerContent);
            root.render(child);
            const marker = new AdvancedMarkerElement({
              map,
              position: { lat, lng },
              content: markerContent,
            });
            bounds.extend(marker.position);
            instances.current.push(marker);
          });
          // Fit Bounds only works when the map is visible
          if (children.length > 1) {
            map.fitBounds(bounds);
          } else {
            // If there's only one marker, set the zoom level and center manually
            map.setCenter(bounds.getCenter());
            map.setZoom(15);
          }
        }
      }
      initInstances();
    }
  }, [map, children, drawPolylines]);

  useEffect(() => {
    // When more than one location is defined we need to recalculate the position because,
    // fitBounds zoom level doesn't work when the map is closed.
    if (map && isVisible && children.length > 1) {
      async function recalculatePosition() {
        const { LatLngBounds } = await Maps.importLibrary('core');
        const bounds = new LatLngBounds();
        children.forEach((child) => {
          const { lat, lng } = child.props;
          bounds.extend({ lat, lng });
        });
        map.fitBounds(bounds);
      }
      recalculatePosition();
    }
  }, [isVisible]);

  return (
    <>
      {!mapLoaded && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 20,
            backgroundColor: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ActivityIndicator />
        </div>
      )}
      <div ref={mapRef} id="map" style={{ width: '100%', height: '100%' }} />
    </>
  );
}

export default MapView;
