/* eslint-disable camelcase */
import { getPostsES } from '../actions/getFeedPosts';
import { uniqueById } from '../utils';

export const posts_middlewares = {
  refresh_feed: {
    // shouldRun: (state) => {
    //   const { isAuthenticated } = state?.Auth || {};
    //   return typeof isAuthenticated === 'boolean';
    // },
    now: (state, action) => ({
      ...state,
      Feed: {
        ...state.Feed,
        isLoadingPosts: true,
        filters: action?.filters || {},
        posts: [],
        // loadedAuth: state.Auth.isAuthenticated,
      },
    }),
    run: (state) => {
      // const { latitude: lat, longitude: lon } = state.Geo || {};
      const {
        distance: radius,
        selectedActivityLevel: activityLevel,
        selectedBudget: budget,
        selectedDuration: duration,
        selectedTags: tags,
      } = state.Feed.filters;
      return getPostsES({
        // lat,
        // lon,
        filters: {
          radius,
          activityLevel,
          budget,
          duration,
          tags,
        },
        // isAuthenticated: state.Auth.isAuthenticated,
      });
    },
    on_success: (response, state) => {
      const { posts = [], isEnd = false, lastScore } = response || {};
      console.log('success', response);
      return {
        ...state,
        Feed: {
          ...state.Feed,
          isLoadingPosts: false,
          isEnd,
          posts,
          lastScore,
        },
      };
    },
    on_fail: (exception, state) => ({
      ...state,
      Feed: {
        ...state.Feed,
        isLoadingPosts: false,
        errorMessage: 'Failed to load posts',
      },
    }),
  },
  getmore_feed: {
    now: (state) => ({
      ...state,
      Feed: {
        ...state.Feed,
        isLoadingPosts: true,
      },
    }),
    run: (state) => {
      // const { latitude: lat, longitude: lon } = state.Geo || {};
      const {
        distance: radius,
        selectedActivityLevel: activityLevel,
        selectedBudget: budget,
        selectedDuration: duration,
        selectedTags: tags,
      } = state.Feed.filters;
      return getPostsES({
        // lat,
        // lon,
        lastScore: state.Feed.lastScore,
        filters: {
          radius,
          activityLevel,
          budget,
          duration,
          tags,
        },
        // isAuthenticated: state.Auth.isAuthenticated,
      });
    },
    on_success: (response, state) => {
      const { posts = [], isEnd = false, lastScore } = response || {};
      return {
        ...state,
        Feed: {
          ...state.Feed,
          isLoadingPosts: false,
          lastScore,
          isEnd,
          posts: uniqueById([...state.Feed.posts, ...posts]),
        },
      };
    },
    on_fail: (exception, state) => ({
      ...state,
      Feed: {
        ...state.Feed,
        isLoadingPosts: false,
        errorMessage: 'Failed to load posts',
      },
    }),
  },
  refresh_post: {
    now: (state, action) => ({
      ...state,
      PostView: {
        ...state.PostView,
        isLoadingPosts: true,
        posts: [],
        postId: action.postId,
      },
    }),
    run: (state, { postId }) => getPostsES({
      postId,
      // isAuthenticated: state.Auth.isAuthenticated,
    }),
    on_success: (response, state) => {
      const { posts = [], isEnd = false, lastScore } = response || {};
      return {
        ...state,
        PostView: {
          ...state.PostView,
          isLoadingPosts: false,
          isEnd,
          posts,
          lastScore,
        },
      };
    },
    on_fail: (exception, state) => ({
      ...state,
      PostView: {
        ...state.PostView,
        isLoadingPosts: false,
        errorMessage: 'Failed to load posts',
      },
    }),
  },
  getmore_post: {
    now: (state) => ({
      ...state,
      PostView: {
        ...state.PostView,
        isLoadingPosts: true,
      },
    }),
    run: ({ PostView, Auth }) => getPostsES({
      postId: PostView.postId,
      lastScore: PostView.lastScore,
      isAuthenticated: Auth.isAuthenticated,
    }),
    on_success: (response, state) => {
      const { posts = [], isEnd = false, lastScore } = response || {};
      return {
        ...state,
        PostView: {
          ...state.PostView,
          isLoadingPosts: false,
          lastScore,
          isEnd,
          posts: uniqueById([...state.PostView.posts, ...posts]),
        },
      };
    },
    on_fail: (exception, state) => ({
      ...state,
      PostView: {
        ...state.PostView,
        isLoadingPosts: false,
        errorMessage: 'Failed to load posts',
      },
    }),
  },
};

export default posts_middlewares;
