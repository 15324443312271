/* eslint-disable react/no-array-index-key */
import React from 'react';
import Typography from '@mui/material/Typography';

const colors = {
  primaryLightDarkBackground: '#36393F', // dark theme background color
  brandColor: '#7289DA', // brand color
  secondary: '#FFFFFF', // White
  error: '#ED4245', // red
  text: '#FFFFFF', // White
  mutedText: '#B9BBBE', // muted text color
  textSecondary: '#72767D', // Gray
};

const styles = {
  bulletContainer: {
    marginBottom: 20,
  },
  titleAndSubContainer: {
    marginBottom: 20,
  },
  bulletTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
  },
  bulletSubtitle: {
    fontSize: 14,
    fontStyle: 'italic',
    color: colors.mutedText,
    marginTop: 8,
  },
  bulletText: {
    fontSize: 16,
    color: colors.mutedText,
    marginBottom: 10,
  },
  bulletPS: {
    fontSize: 16,
    color: colors.mutedText,
    marginBottom: 10,
  },
};

function LegalContent(params) {
  const { content } = params;
  console.log('content: ', content);

  return (
    content?.map((item) => (
      <div
        id={item?.title?.split(' ').join('')}
        key={item?.title || item}
        style={styles.bulletContainer}
      >
        <div style={styles.titleAndSubContainer}>
          <Typography style={styles.bulletTitle}>{item?.title}</Typography>
          {item?.subtitle
            ? <Typography style={styles.bulletSubtitle}>{item?.subtitle}</Typography> : null}
        </div>
        {item?.details?.map((c) => (
          <Typography style={styles.bulletText} key={c}>
            {c}
          </Typography>
        ))}
        {item?.ps
          ? <Typography style={styles.bulletPS}>{item?.ps}</Typography> : null}
      </div>
    ))
  );
}

export default LegalContent;
