import React from 'react';
import './Footer.css';
import {
  Link,
} from 'react-router-dom';

function FooterComponent() {
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.1/8 is considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
  );
  console.log('isLocalhost: ', isLocalhost);

  return (
    <footer className="footer" role="contentinfo">
      <h2 className="sr-only" id="sr-footer-heading">Footer</h2>
      <div className="footer-content">
        <div className="xpfy-logo">
          <Link className="logo-link" to="/">
            <img
              src="/android-chrome-192x192.png"
              alt="XPFY"
              className="logo-icon-footer"
            />
            <p>© 2023 XPFY LLC.</p>
          </Link>
        </div>

        <nav aria-label="Footer" className="footer-navigation">
          <h3 className="sr-only" id="sr-footer-heading">Footer navigation</h3>
          <ul className="footer-link-ul" aria-labelledby="sr-footer-heading">
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/legal/terms">Terms</Link>
                : (
                  <a
                    href="/legal/terms"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to terms&quot;,&quot;label&quot;:&quot;text:terms&quot;}"
                  >
                    Terms
                  </a>
                )}
            </li>
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/legal/privacy/policy">Privacy</Link>
                : (
                  <a
                    href="/legal/privacy/policy"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to privacy&quot;,&quot;label&quot;:&quot;text:privacy&quot;}"
                  >
                    Privacy
                  </a>
                )}
            </li>
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/legal/guidelines">Guidelines</Link>
                : (
                  <a
                    href="/legal/guidelines"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to guidelines&quot;,&quot;label&quot;:&quot;text:guidelines&quot;}"
                  >
                    Guidelines
                  </a>
                )}
            </li>
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/legal/cookies">Cookies Policy</Link>
                : (
                  <a
                    href="/legal/cookies"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to cookies&quot;,&quot;label&quot;:&quot;text:cookies&quot;}"
                  >
                    Cookies Policy
                  </a>
                )}
            </li>
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/about">About</Link>
                : (
                  <a
                    href="https://xpfy.app/about"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to about&quot;,&quot;label&quot;:&quot;text:about&quot;}"
                  >
                    About
                  </a>
                )}
            </li>
            <li className="footer-link">
              {isLocalhost
                ? <Link to="/legal/feedback">Contact XPfy</Link>
                : (
                  <a
                    href="/legal/feedback"
                    data-analytics-event="{&quot;category&quot;:&quot;Footer&quot;,&quot;action&quot;:&quot;go to feedback&quot;,&quot;label&quot;:&quot;text:feedback&quot;}"
                  >
                    Contact XPfy
                  </a>
                )}
            </li>
          </ul>
        </nav>
      </div>
      {/* <nav className="navigation">
          <Link to="/">Home</Link>
          <Link to="#contact">Contact</Link>
          <Link to="/legal/privacy/policy">Privacy Policy</Link>
          <Link to="/legal/terms">Terms And Conditions</Link>
        </nav> */}
    </footer>
  );
}

export default FooterComponent;
