/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import {
  // getFollowerCount,
  // getFollowingCount,
  // getProfileBookmarkedPosts,
  getProfileCreatedPosts,
  getProfileInfo,
  // getProfileLikedPosts,
} from '../actions/profile';
import { uniqueById } from '../utils';

export const profile_middlewares = {
  refresh_profile: {
    now: (state, action) => ({
      ...state,
      Profile: {
        userSub: action.userSub,
        info: {
          isLoading: true,
        },
        created: {
          isLoading: true,
        },
        liked: {
          isLoading: true,
        },
        bookmarks: {
          isLoading: true,
        },
        followers: undefined,
        following: undefined,
      },
    }),
    run: (state, action, dispatch) => {
      const { userSub } = action;
      getProfileInfo(userSub).then((response) => dispatch({ type: 'update_profile_field', updateType: 'info', response }));
      getProfileCreatedPosts(userSub).then((response) => dispatch({
        type: 'update_profile_field',
        updateType: 'created',
        response,
      }));
    },
  },
  load_more: {
    now: (state, action) => {
      const { listType } = action;
      return {
        ...state,
        Profile: {
          ...state.Profile,
          [listType]: {
            ...state.Profile[listType],
            isLoading: true,
          },
        },
      };
    },
    run: (state, action) => {
      const { listType } = action;
      const { userSub } = state.Profile;
      const {
        lastScore,
        isEnd,
      } = state.Profile[listType];
      if (listType === 'created') {
        if (isEnd) return Promise.resolve({ posts: [] });
        return getProfileCreatedPosts(userSub, lastScore);
      }
      // if (listType === 'liked') {
      //   if (isEnd) return Promise.resolve({ posts: [] });
      //   return getProfileLikedPosts(userSub, lastScore);
      // }
      // if (listType === 'bookmarks') {
      //   if (isEnd) return Promise.resolve({ posts: [] });
      //   return getProfileBookmarkedPosts(userSub, lastScore);
      // }
      return {};
    },
    on_success: (res, state, action) => {
      const { listType } = action;
      const { posts: curPosts } = state.Profile[listType];
      const { posts = [], ...rest } = res;
      if (!posts.length) {
        return {
          ...state,
          Profile: {
            ...state.Profile,
            [listType]: {
              ...state.Profile[listType],
              isLoading: false,
              isEnd: true,
            },
          },
        };
      }

      const arrPosts = uniqueById([...curPosts, ...posts]);

      return {
        ...state,
        Profile: {
          ...state.Profile,
          [listType]: {
            ...state.Profile[listType],
            isEnd: arrPosts.length >= res?.count,
            ...rest,
            posts: arrPosts,
            isLoading: false,
          },
        },
      };
    },
    on_fail: (exception, state, action) => {
      const { listType } = action;
      return {
        ...state,
        Profile: {
          ...state.Profile,
          [listType]: {
            ...state.Profile[listType],
            isLoading: false,
            errorMessage: exception.message,
          },
        },
      };
    },
  },
  update_profile_field: {
    now: (state, action) => {
      const { updateType, response } = action;
      return {
        ...state,
        Profile: {
          ...state.Profile,
          [updateType]:
            typeof response === 'object'
              ? {
                ...response,
                isLoading: false,
                ...(response?.posts && response?.count
                  ? { isEnd: response.posts.length >= response.count } : {}),
              }
              : response,
        },
      };
    },
  },
};
