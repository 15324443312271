import React from 'react';

const styles = {
  progressBarContainer: {
    flex: 1,
    alignSelf: 'stretch',
    height: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 5,
  },
  progressBar: {
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
  },
};

function ProgressBar({ progress }) {
  return (
    <div style={styles.progressBarContainer}>
      <div style={{ ...styles.progressBar, width: `${progress}%` }} />
    </div>
  );
}

export default ProgressBar;
