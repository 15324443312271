// COPY PASTA FROM APP, we shoudl probably import from the same spot
// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration-analytics

const SANDBOX = {
  aws_project_region: 'us-west-2',

  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_6ezGcvOqa',
  aws_user_pools_web_client_id: '7stkgqj70s612pn4tbuhn62liq',

  aws_appsync_region: 'us-west-2',
  aws_appsync_graphqlEndpoint: 'https://xnzmvmlqsfc2zkskemkzfauige.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-ue6jjgs4sfdktauxqixjgi6au4',
  aws_appsync_userpool_id: 'us-west-2_6ezGcvOqa',
  aws_appsync_web_client_id: '7stkgqj70s612pn4tbuhn62liq',

  aws_user_files_s3_bucket_region: 'us-west-2', // (required) - Amazon S3 bucket region
  aws_user_files_s3_bucket: 'https://xpfy-user-media-sandbox.s3.us-west-2.amazonaws.com',
  aws_mobile_analytics_app_region: 'us-west-2', // (required) Amazon Pinpoint Project region
};

export default SANDBOX;
