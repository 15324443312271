import React, { useState, useRef } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import LinearProgress from '@mui/material/LinearProgress';
import axios from 'axios';
import validateAndFilterSelectedFiles from '../utils/imgValidation';
import { createFeedback } from '../context/actions/createFeedback';

function uploadToS3(presignedUrl, file, onUploadProgress) {
  return axios.put(presignedUrl, file, {
    headers: { 'Content-Type': file.type },
    onUploadProgress,
  });
}

const colors = {
  primaryLightDarkBackground: '#36393F', // dark theme background color
  brandColor: '#7289DA', // brand color
  error: '#ED4245', // red
  text: '#FFFFFF', // White
  mutedText: '#B9BBBE', // muted text color
  textSecondary: '#72767D', // Gray
};

const styles = {
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primaryLightDarkBackground,
    padding: 25,
  },
  header: {
    color: colors.text,
    fontSize: 26,
    marginTop: 20,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentNote: {
    color: colors.mutedText,
    alignSelf: 'flex-start',
  },
  formControl: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  selectLabel: {
    color: colors.mutedText,
  },
  selectText: {
    // height: '2.5rem',
    color: colors.mutedText,
    borderColor: colors.mutedText,
    borderWidth: 0.5,
    borderRadius: 4,
    borderStyle: 'solid',
  },
  imgNamesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,
  },
  chip: {
    marginRight: 10,
  },
  uploadBtn: {
    borderColor: colors.brandColor,
    color: colors.brandColor,
    width: 170,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checkbox: {
    color: colors.brandColor,
  },
  checkboxLabel: {
    color: colors.mutedText,
  },
  submitBtnContainer: {
    marginTop: 25,
    marginBottom: 40,
  },
  submitBtn: {
    backgroundColor: colors.brandColor,
    width: 300,
  },
  error: {
    fontSize: 15,
    color: colors.error,
    marginTop: 5,
    textAlign: 'center',
  },
  errorsBlock: {
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
};

const topicOptions = [
  { value: 'General Account Inquiry' },
  { value: 'Change Account Information' },
  { value: 'Report a bug' },
  { value: 'Feature request' },
  { value: 'Other' },
];

const subTopicOptions = {
  'General Account Inquiry': [
    { value: 'Acount Security' },
    { value: 'Recover Account' },
    { value: 'Age Verification' },
    { value: 'Deleted Account' },
    { value: 'Hacked Account' },
    { value: 'Login Issue' },
    { value: 'Banned Account' },
    { value: 'Other' },
  ],
  'Change Account Information': [
    { value: 'Acount Security' },
    { value: 'Recover Account' },
    { value: 'Age Verification' },
  ],
};

const maxSizeMB = 10;
const maxSizeBytes = maxSizeMB * 1024 * 1024;

const validateEmail = (emailaddress) => {
  console.log('emailaddress: ', emailaddress);
  // this one legit too /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(emailaddress);
};

function Feedback() {
  const inputRef = useRef(null);
  const [userTopic, setUserTopic] = useState('');
  const [form, setForm] = useState({
    topic: '',
    subTopic: '',
    email: '',
    username: '',
    description: '',
  });
  const [filesState, setFilesState] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [imgErrorMessages, setImgErrorMessages] = useState([]);
  const [progress, setProgress] = useState(100);
  const [showSubTopic, setShowSubTopic] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);

  const validationErrors = [];
  const handleTopicChange = (event) => {
    const t = event.target.value;
    console.log('event.target.value TOP: ', event.target.value);
    setShowOther(!!(t === 'Other'));

    if (subTopicOptions[t]) {
      setShowSubTopic(true);
    } else {
      setShowSubTopic(false);
      setForm((prevForm) => ({
        ...prevForm,
        subTopic: '',
      }));
    }
    setForm((prevForm) => ({
      ...prevForm,
      topic: event.target.value,
    }));
  };

  const handleSubTopicChange = (event) => {
    console.log('event.target.value SUB: ', event.target.value);
    setForm((prevForm) => ({
      ...prevForm,
      subTopic: event.target.value,
    }));
  };

  const submitForm = async () => {
    const params = { ...form };
    if (!form?.subTopic && userTopic) {
      params.subTopic = userTopic;
    }

    console.log('submitForm params: ', params);

    const files = filesState?.length ? Array.from(filesState) : [];

    if (filesState?.length) {
      // Attaching files to the formData
      params.files = files.map(({ name }) => name);
    }

    setUploadProgress(files.map(() => 0));
    const totalSize = files.reduce((acc, f) => acc + f.size, 0);
    const weights = files.map((f) => f.size / totalSize);

    function onUploadProgress(event, index) {
      const { progress: p } = event;
      console.log(uploadProgress);
      setUploadProgress((cur) => {
        const updatedProgresses = [...cur];
        updatedProgresses[index] = p;
        const totalProgress = updatedProgresses.reduce((acc, prog, i) => (
          acc + (weights[i] * prog * 100)
        ), 0);
        console.log(totalProgress);
        setProgress(
          totalProgress,
        );
        return updatedProgresses;
      });
    }

    try {
      setProgress(0);
      createFeedback(params).then((signedUrls) => {
        console.log('signed', signedUrls);
        if (!signedUrls?.length) return [];
        return Promise.all(
          signedUrls.map((url, i) => uploadToS3(url, files[i], (e) => onUploadProgress(e, i))),
        );
      }).then(() => {
        // DONE
        // TODO: Do something? maybe clear up the form and confirm the submission
        setProgress(100);
      });
    } catch (error) {
      setProgress(0);
      console.log(`Error: ${error}`);
    }
  };

  const validateForm = () => {
    if (!form?.topic) {
      validationErrors.push({
        description: 'Please choose a topic.',
      });
    }

    if (showSubTopic && !form?.subTopic) {
      validationErrors.push({
        description: 'Please choose sub category.',
      });
    }

    if (form?.topic === 'Other' && !userTopic) {
      validationErrors.push({
        description: 'Please provide a subject of your request.',
      });
    }

    // todo: trim email and other
    if (!validateEmail(form?.email)) {
      validationErrors.push({
        description: 'Invalid email address.',
      });
    }

    if (!form?.description || form?.description.trim().length < 10) {
      validationErrors.push({
        description: 'Please tell us more.',
      });
    }

    if (!checkbox1 || !checkbox2) {
      validationErrors.push({
        description: 'Please agree to all the terms.',
      });
    }

    if (!showSubTopic) {
      setForm((prevForm) => ({
        ...prevForm,
        subTopic: '',
      }));
    }

    if (form?.topic !== 'Other') setUserTopic('');
    // if (!showOther) setShowOther(false);
    return validationErrors;
  };

  const handleSubmit = (event) => {
    console.log('validate form: ', form);
    event.preventDefault();

    const formErrors = validateForm();

    if (formErrors.length) {
      setErrorMessages(formErrors);
    } else {
      setErrorMessages([]);
      setImgErrorMessages([]);

      submitForm();
    }
  };

  // update state on file select
  const handleFileSelect = async (event) => {
    if (event?.target?.files?.length) {
      setImgErrorMessages([]);
      const imgValidationMessages = [];
      try {
        const res = await validateAndFilterSelectedFiles(event.target.files);
        console.log('validateAndFilterSelectedFiles res', res);

        const files = [];
        for (let i = 0; i < res.length; i += 1) {
          // filter all unsupported
          console.log('res[i]: ', res[i]);
          if (res[i].supported) {
            files.push(res[i]?.file);
          } else {
            imgValidationMessages.push({
              description: `${res[i]?.file?.name} file type is not supported.`,
            });
          }
        }

        if (imgValidationMessages.length) setImgErrorMessages(imgValidationMessages);

        if (files.length) {
          const totalSize = files
            .reduce((total, fileBuffer) => total + fileBuffer.size, 0);
          if (totalSize > maxSizeBytes) {
            imgValidationMessages.push({
              description: `Combined size of all files should be less then ${maxSizeMB}MB.`,
            });
            setImgErrorMessages(imgValidationMessages);
            return;
          }

          const dupFiles = filesState
            .filter((value) => files
              .some((oneElement) => oneElement.name === value.name))
            .map((oneElement) => oneElement.name);

          console.log('dupFiles: ', dupFiles);

          setFilesState([
            ...filesState,
            ...files.filter((value) => !dupFiles
              .some((oneDuplicate) => oneDuplicate === value.name)),
          ]);
        }
      } catch (err) {
        imgValidationMessages.push({
          description: err.message,
        });
        setImgErrorMessages(imgValidationMessages);
      }
    }
  };

  const handleDelete = (file) => {
    const fileToDelete = file?.name;
    setFilesState(filesState.filter((item) => item.name !== fileToDelete));
  };

  const Style = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.mutedText,
    },
    '& .MuiSvgIcon-root': {
      color: colors.mutedText,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.mutedText,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.mutedText,
    },
    // for input field text and placeholder
    '& .MuiOutlinedInput-root': {
      color: colors.text,
    },
    '& .MuiChip-root': {
      color: colors.text,
    },
    '& .MuiChip-label': {
      color: colors.text,
    },
  };

  return (
    <div style={styles.feedbackContainer}>
      <div style={styles.header}>
        <h2>Contact XPfy</h2>
      </div>

      <form onSubmit={handleSubmit} style={styles.contentContainer}>
        <div style={styles.contentNote}>
          <h4>Information you provide will solely be used to address your concerns.</h4>
        </div>
        <FormControl fullWidth style={styles.formControl}>
          <InputLabel style={styles.selectLabel} id="topic-select-label">Topic</InputLabel>
          <Select
            style={styles.selectText}
            id="topic-select"
            value={form?.topic}
            label="Topic *"
            onChange={handleTopicChange}
            sx={Style}
          >
            {topicOptions?.map((t) => (
              <MenuItem key={t.value} value={t.value}>{t.value}</MenuItem>
            ))}
          </Select>
          <FormHelperText style={styles.selectLabel}>Required</FormHelperText>
        </FormControl>
        {showSubTopic
          ? (
            <FormControl fullWidth style={styles.formControl}>
              <InputLabel style={styles.selectLabel} id="subtopic-select-label">Sub Category</InputLabel>
              <Select
                style={styles.selectText}
                id="subtopic-select"
                value={form?.subTopic}
                label="Sub category *"
                onChange={handleSubTopicChange}
                sx={Style}
              >
                {subTopicOptions[form?.topic]?.map((t) => (
                  <MenuItem key={t.value} value={t.value}>{t.value}</MenuItem>
                ))}
              </Select>
              <FormHelperText style={styles.selectLabel}>Required</FormHelperText>
            </FormControl>
          )
          : null}
        {showOther
          ? (
            <FormControl fullWidth style={styles.formControl}>
              <TextField
                onChange={(event) => setUserTopic(event?.target?.value)}
                style={styles.selectText}
                id="specify-input"
                placeholder="Please provide a subject of your request..."
                aria-describedby="specify-helper-text"
                inputMode="text"
                sx={Style}
              />
              <FormHelperText style={styles.selectLabel} id="specify-helper-text">Required</FormHelperText>
            </FormControl>
          )
          : null}
        <FormControl fullWidth style={styles.formControl}>
          <TextField
            // required
            autoCapitalize="none"
            onChange={(event) => setForm((prevForm) => ({
              ...prevForm,
              email: event.target.value,
            }))}
            style={styles.selectText}
            id="email-input"
            placeholder="Email address..."
            aria-describedby="email-helper-text"
            inputMode="email"
            sx={Style}
          />
          <FormHelperText style={styles.selectLabel} id="email-helper-text">Required</FormHelperText>
        </FormControl>
        <FormControl fullWidth style={styles.formControl}>
          <TextField
            autoCapitalize="none"
            onChange={(event) => setForm((prevForm) => ({
              ...prevForm,
              username: event.target.value,
            }))}
            style={styles.selectText}
            id="username-input"
            placeholder="Username..."
            aria-describedby="username-helper-text"
            inputMode="text"
            sx={Style}
          />
          <FormHelperText style={styles.selectLabel} id="username-helper-text">Optional</FormHelperText>
        </FormControl>
        <FormControl fullWidth style={styles.formControl}>
          <TextField
            // required
            multiline
            minRows={4}
            onChange={(event) => setForm((prevForm) => ({
              ...prevForm,
              description: event.target.value,
            }))}
            style={styles.selectText}
            id="description-input"
            placeholder="Tell us more..."
            aria-describedby="description-helper-text"
            inputMode="text"
            sx={Style}
          />
          <FormHelperText style={styles.selectLabel} id="description-helper-text">Required</FormHelperText>
        </FormControl>
        <div style={styles.contentNote}>
          <h3>Attachments (Optional)</h3>
          <p>Supported file: WebP, GIF, JPEG, JPG, PNG, TIFF, PDF</p>
          <input
            style={{ display: 'none' }}
            type="file"
            multiple
            onChange={handleFileSelect}
            ref={inputRef}
          />

          {filesState?.length
            ? (
              <div style={styles.imgNamesContainer}>
                {filesState?.map((f) => (
                  <Chip
                    style={styles.chip}
                    key={f.name}
                    label={f.name}
                    variant="outlined"
                    onDelete={() => handleDelete(f)}
                    sx={Style}
                  />
                ))}
              </div>
            )
            : null}

          <div style={styles.errorsBlock}>
            {(imgErrorMessages.length) ? imgErrorMessages.map((message) => {
              const { description: des } = message;
              return (
                <p
                  key={des}
                  style={styles.error}
                >
                  {des}
                </p>
              );
            }) : null}
          </div>

          <div style={styles.submitBtnContainer}>
            <Button
              style={styles.uploadBtn}
              variant="outlined"
              onClick={() => inputRef.current.click()}
            >
              Upload
            </Button>
          </div>
        </div>
        <div style={styles.checkboxContainer}>
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={checkbox1}
                  onChange={(e) => setCheckbox1(e.target.checked)}
                  style={styles.checkbox}
                />
              )
            }
            label="I affirm, to the fullest extent of my ability and understanding, that all the information provided above is accurate and truthful."
            style={styles.checkboxLabel}
          />
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={checkbox2}
                  onChange={(e) => setCheckbox2(e.target.checked)}
                  style={styles.checkbox}
                />
              )
            }
            label="By submitting, I acknowledge that XPfy will process my data in accordance with XPfy’s Privacy Policy."
            style={styles.checkboxLabel}
          />
        </div>
        <div style={styles.errorsBlock}>
          {(errorMessages.length) ? errorMessages.map((message) => {
            const { description: des } = message;
            return (
              <p
                key={des}
                style={styles.error}
              >
                {des}
              </p>
            );
          }) : null}
        </div>
        <div style={styles.submitBtnContainer}>
          <Button type="submit" style={styles.submitBtn} variant="contained">Submit</Button>
        </div>
        <LinearProgress sx={{ width: '100%' }} variant="determinate" value={progress} />
      </form>
    </div>
  );
}

export default Feedback;
