import React from 'react';

function Image({
  source, defaultSource, style, className,
}) {
  const handleError = (e) => {
    e.target.src = defaultSource;
  };

  return (
    <img src={source.uri} style={style} className={className} onError={handleError} alt="Profile" />
  );
}

export default Image;
