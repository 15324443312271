/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import hashStringToColor from '../utils/hashStringToColor';

export function Tag({ tagName, onClick = () => {}, disabled }) {
  const color = hashStringToColor(tagName);
  return (
    <div
      disabled={disabled}
      onClick={onClick}
      style={{ ...styles.tagContainer, ...(disabled ? { backgroundColor: '#33333333', borderColor: '#333333' } : { backgroundColor: `${color}66`, borderColor: color }) }}
    >
      <p style={styles.tagText}>{tagName}</p>
    </div>
  );
}

function TagList({ tags = [] }) {
  const taglist = Array.isArray(tags) ? tags : tags.split(', ');
  return taglist?.length
    ? (
      <div style={styles.tagListContainer}>
        {taglist.map((tag) => (
          <Tag key={tag} tagName={tag} />
        ))}
      </div>
    )
    : null;
}

const styles = {
  tagListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    // backgroundColor: '#23272A',
    // paddingTop: 10,
    // paddingLeft: 10,
    // borderRadius: 8,
  },
  tagContainer: {
    display: 'flex',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 8,
    marginRight: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#4F545C',
  },
  tagText: {
    fontSize: 12,
    color: '#FFFFFF',
  },
};

export default TagList;
