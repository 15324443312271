const determineMIMEType = (header) => {
  console.log('header: ', header);
  let supportedImageType = true;

  switch (header) {
    case '0d0a3541':
    case '25504446':
      // type = ".pdf";
      break;
    case '89504e47':
      // type = "image/png";
      break;
    case '47494638':
      // type = "image/gif";
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      // type = "image/jpeg";
      break;
    case '52494646':
      // type = "image/webp";
      break;
    case '49492a00':
    case '4d4d002a':
      // type = "image/tiff";
      break;
    default:
      // type = "unknown"; // Or you can use the blob.type as fallback
      supportedImageType = false;
      break;
  }

  return supportedImageType;
};

const validateAndFilterSelectedFiles = (blobArr) => {
  const promiseArr = [];

  // for each selected image check 4 first bytes to filter images from other files
  for (let i = 0; i < blobArr.length; i += 1) {
    const file = blobArr[i];

    // create array of Promises with value evaluated in .onload
    promiseArr.push(new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
        console.log('Uint8Array: ', arr);
        let header = '';

        for (let index = 0; index < arr.length; index += 1) {
          header += arr[index].toString(16).padStart(2, '0');
        }

        resolve({
          file,
          supported: determineMIMEType(header),
        });
      };

      reader.readAsArrayBuffer(file);

      reader.onerror = (error) => {
        reject(error);
      };
    }));
  }

  return Promise.all(promiseArr);
};

export default validateAndFilterSelectedFiles;
