import { API, graphqlOperation } from 'aws-amplify';

export const GET_POSTS_QUERY = /* GraphQL */ `
  query getPosts($filters: PostFiltersInput, $lastScore: String, $postId: ID) {
    getPosts(filters: $filters, lastScore: $lastScore, postId: $postId) {
      posts {
        createdAt
        id
        userSub
        likes
        bookmarks
        userProfile {
          userName
          fullname
        }
        durations
        highlights {
          budget
          duration
          activityLevel
        }
        locations {
          lat
          lon
          formattedAddress,
          name
        }
        tags
        description
        isEvent
        startDate
        endDate
        status
      }
      isEnd
      lastScore
    }
  }
`;

export function getPostsES({
  lat, lon, lastScore, isAuthenticated = false, filters = {},
  postId,
}) {
  const {
    radius,
    activityLevel = [],
    budget = [],
    duration = [],
    tags = [],
    status,
  } = filters;
  const operation = graphqlOperation(GET_POSTS_QUERY, {
    ...(postId ? { postId } : {}),
    filters: {
      ...(lat && lon
        ? {
          distance: {
            ...(radius && radius !== 'No Limit' ? { radius: `${radius}mi` } : {}),
            lat,
            lon,
          },
        }
        : {}),
      ...(activityLevel.length ? { activityLevel } : {}),
      ...(budget.length ? { budget } : {}),
      ...(duration.length ? { duration } : {}),
      ...(tags.length ? { tags } : {}),
      ...(status ? { status } : {}),
    },
    ...(lastScore ? { lastScore } : {}),
  });
  return API.graphql({
    ...operation,
    authMode: isAuthenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY',
  })
    .then((response) => {
      const { data } = response;
      const { getPosts } = data;
      return getPosts;
    })
    .catch(({ data, errors }) => {
      console.log('GET_POSTS_QUERY errors: ', JSON.stringify(errors));
      const { getPosts } = data;
      return getPosts;
    });
}
