/* eslint-disable no-underscore-dangle */
import React, { useRef, useCallback, useEffect } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import './VirtualizedList.css';

function VirtualizedList({
  data,
  ItemRenderer,
  EmptyListRenderer,
  onEndReached,
  onEndReachedThreshold = 200,
  initialScrollIndex = 0,
  extraData,
  onSnap = () => {},
  keyGetter,
  itemSize: iSize,
}) {
  const listRef = useRef(null);
  const scrollTimeout = useRef();

  const handleScroll = useCallback(
    (event) => {
      if (listRef.current) {
        const { scrollOffset, scrollDirection } = event;
        const {
          itemSize,
          itemCount,
          height: clientHeight,
        } = listRef?.current?.props || {};

        if (!scrollOffset || !itemSize || !itemCount) return;
        const scrollHeight = itemSize * itemCount;

        // Snap to item
        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = setTimeout(() => {
          const index = Math.max(0, Math.min(scrollDirection === 'forward'
            ? Math.ceil(scrollOffset / itemSize)
            : Math.floor(scrollOffset / itemSize), data?.length ? data.length - 1 : 0));
          const targetScrollTop = index * itemSize;

          setTimeout(() => {
            if (listRef.current) {
              listRef.current._outerRef.scrollTo({
                top: targetScrollTop,
                behavior: 'smooth',
              });
            }
            onSnap(index);
          }, 0);
        }, 100);

        // Check if end is reached
        const distanceFromEnd = scrollHeight - scrollOffset - clientHeight;
        if (distanceFromEnd < onEndReachedThreshold) {
          onEndReached();
        }
      }
    },
    [onEndReached, onEndReachedThreshold, data],
  );

  const Row = useCallback((props) => {
    const { index, style, data: innerData } = props;
    const {
      items,
      width,
      height,
      extraData: extra,
    } = innerData || {};
    const item = items[index];
    return (
      <div style={style} className="parent-container">
        {ItemRenderer({
          item, extraData: extra, index, width, height,
        })}
      </div>
    );
  }, [ItemRenderer]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(initialScrollIndex, 'start');
    }
  }, [initialScrollIndex]);

  if (EmptyListRenderer && data.length < 1) {
    return <EmptyListRenderer />;
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          itemData={{
            items: data, width, height, extraData,
          }}
          itemCount={data.length}
          itemSize={iSize || height}
          itemKey={(index, { items }) => keyGetter(items[index])}
          width={width}
          ref={listRef}
          overscanCount={5}
          overscanRowCount={5}
          onScroll={handleScroll}
          className="list"
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
}

export default VirtualizedList;
