import React from 'react';
import { Tag } from './TagList';

export function stringifyDateRange(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  // Extracting the day, month, and year from the start and end dates
  const startDay = start.getDate();
  const startMonth = start.toLocaleString('default', { month: 'short' });
  const startYear = start.getFullYear();
  const startWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(start);

  const endDay = end.getDate();
  const endMonth = end.toLocaleString('default', { month: 'short' });
  const endYear = end.getFullYear();
  const endWeekDay = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(end);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Resetting the time to 00:00:00 for accurate comparison

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1); // Getting tomorrow's date

  if (end < today) {
    return 'Past Event';
  }

  const isInSameWeek = (date1, date2) => {
    const oneDayInMs = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.round((date2 - date1) / oneDayInMs);
    return differenceInDays < 7 && start > today;
  };

  if (start.toDateString() === end.toDateString()) {
    if (start.toDateString() === today.toDateString()) return 'Today';
    if (start.toDateString() === tomorrow.toDateString()) return 'Tomorrow';
    if (isInSameWeek(today, start)) return startWeekDay;
    return `${startMonth} ${startDay}`;
  }

  if (startMonth === endMonth && startYear === endYear) {
    if (isInSameWeek(start, end)) return `${startWeekDay} - ${endWeekDay}`;
    return `${startMonth} ${startDay} - ${endDay}`;
  }

  return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
}

function DateRange({
  startDate, endDate, isEvent, onPress,
}) {
  return (isEvent && startDate && endDate)
    ? (
      <Tag onPress={onPress} tagName={stringifyDateRange(startDate, endDate)} />
    ) : null;
}

export default DateRange;
