import { API } from 'aws-amplify';
import graphqlOperation from '../../utils/operationWrapper';

export const CREATE_FEEDBACK = /* GraphQL */ `
  mutation createFeedback($input: CreateFeedbackInput!) {
    createFeedback(input: $input)
  }
`;

export const createFeedback = (params) => API.graphql(
  graphqlOperation(CREATE_FEEDBACK, {
    input: params,
  }),
).then(({ data }) => data.createFeedback).catch((error) => {
  console.log('CREATE_FEEDBACK error: ', JSON.stringify(error));
  throw new Error('CREATE_FEEDBACK error');
});
