export function uniqueById(arr) {
  const seen = new Map();

  return arr.reduce((acc, item) => {
    if (!seen.has(item.id)) {
      seen.set(item.id, true);
      acc.push(item);
    }
    return acc;
  }, []);
}

export function arrayMove(arr, fromIndex, toIndex) {
  const newArr = [...arr];
  const element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
}

export const setField = (obj, path, value) => {
  const updatedObj = { ...obj };
  let temp = updatedObj;
  path.forEach((fieldName, index) => {
    temp[fieldName] = index >= path.length - 1 ? value : { ...temp[fieldName] };
    temp = temp[fieldName];
  });
  return updatedObj;
};

export function generateUUID() {
  let uuid = '';
  const hexDigits = '0123456789ABCDEF';
  for (let i = 0; i < 36; i += 1) {
    if (i === 8 || i === 13 || i === 18 || i === 23) {
      uuid += '-';
    } else if (i === 14) {
      uuid += '4';
    } else {
      uuid += hexDigits[Math.floor(Math.random() * 16)];
    }
  }
  return uuid;
}
