/* eslint-disable camelcase */
import React, { useEffect, useMemo } from 'react';
import { profile_middlewares } from './middlewares/profile.mw';
import { auth_middlewares } from './middlewares/auth.mw';
import { posts_middlewares } from './middlewares/posts.mw';
import { setField } from './utils';
import { initialState } from './initialState';

const AppContext = React.createContext();

const middlewares = {
  ...profile_middlewares,
  ...auth_middlewares,
  ...posts_middlewares,
};

function reducer(state, action, isForecast) {
  // Comment this line to silence state updates
  if (!isForecast) {
    // console.log(JSON.stringify(state, null, 2))
    // console.log(JSON.stringify(action, null, 2));
  }
  switch (action.type) {
    case 'toggle_mute':
      return {
        ...state,
        Feed: {
          ...state.Feed,
          muted: !state.Feed.muted,
        },
      };
    case 'update_geo_location':
      return {
        ...state,
        Geo: { ...action.value },
      };
    case 'set_field':
      return setField(state, action.path, action.value);
    case 'on_success':
      return (
        middlewares?.[action.actionType]?.on_success?.(
          action.response,
          state,
          action.action,
        ) || state
      );
    case 'on_fail':
      return (
        middlewares?.[action.actionType]?.on_fail?.(
          action.exception,
          state,
          action.action,
        ) || state
      );
    default: {
      const {
        now = (s) => s,
        shouldRun = () => true,
      } = middlewares?.[action?.type] || {};
      return shouldRun(state, action, isForecast) ? (now(state, action) || state) : state;
    }
  }
}

function middleware(state, action, dispatch) {
  const {
    run = () => Promise.resolve(),
    shouldRun = () => true,
  } = middlewares?.[action?.type] || {};
  if (!middlewares?.[action?.type]) return Promise.resolve();
  const forecastedState = reducer(state, action, true);
  const timestamp = Math.floor(new Date().getTime() / 1000);
  if (!shouldRun(state, action)) {
    return Promise.resolve();
  }
  return run?.(forecastedState, action, dispatch)
    ?.then((res) => dispatch({
      type: 'on_success',
      response: res,
      actionType: action.type,
      action,
      timestamp,
    }))
    ?.catch((exception) => dispatch({
      type: 'on_fail',
      exception,
      actionType: action.type,
      action,
      timestamp,
    }));
}

export function useAppState() {
  return React.useContext(AppContext);
}

export const withAppContext = (Component) => function withAppContextWrapper() {
  const [state, dispatchToReducer] = React.useReducer(reducer, initialState);

  const providerState = useMemo(
    () => ({
      state,
      dispatch: (action) => {
        dispatchToReducer(action);
        middleware(state, action, dispatchToReducer);
      },
    }),
    [state, dispatchToReducer],
  );

  useEffect(() => {
    window.Auth = state.Auth;
  }, [state?.Auth]);

  return (
    <AppContext.Provider value={providerState}>
      <Component />
    </AppContext.Provider>
  );
};

export default AppContext;
