import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LanguageIcon from '@mui/icons-material/Language';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloseIcon from '@mui/icons-material/Close';
import privacyPolicyUSJSON from '../data/privacyPolicyUS.json';
import LegalContent from '../components/LegalContent';
import TableOfContent from '../components/TableOfContent';

const colors = {
  primaryLightDarkBackground: '#36393F', // dark theme background color
  brandColor: '#7289DA', // brand color
  secondary: '#FFFFFF', // White
  error: '#ED4245', // red
  text: '#FFFFFF', // White
  mutedText: '#B9BBBE', // muted text color
  textSecondary: '#72767D', // Gray
};

const styles = {
  // legalContainer: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   paddingLeft: '50px',
  //   paddingRight: '50px',
  //   paddingTop: '30px',
  //   paddingBottom: '30px',
  //   textWrap: 'wrap',
  //   backgroundColor: colors.primaryLightDarkBackground,
  // },
  legalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: colors.primaryLightDarkBackground,
  },
  legalContentContainer: {
    flex: 1, // Allow it to take the remaining width
    padding: '50px', // Add padding as needed
    overflowY: 'auto', // Enable vertical scrolling if needed
  },
  regionSwitchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  globeIcon: {
    color: colors.mutedText,
    marginLeft: 5,
  },
  forgotPasswordBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primaryLightDarkBackground,
    padding: 8,
    borderRadius: 5,
    marginTop: 20,
    border: '1px solid', // Add a thin border
    borderColor: colors.mutedText,
  },
  forgotPasswordBtnText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.mutedText,
    textAlign: 'center',
    marginLeft: 8,
    marginRight: 8,
  },
  modal: {
    display: 'flex', /* Hidden by default */
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed', /* Stay in place */
    zIndex: 1, /* Sit on top */
    left: 0,
    top: 0,
    width: '100%', /* Full width */
    height: '100%', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgba(0, 0, 0, 0.4)', /* Black w/ opacity */
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 10,
    justifyContent: 'flex-start',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 40,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
  },
  closeIcon: {
    color: 'black',
  },
  modalMiddle: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },
  regionOption: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  regionOptionText: {
    color: 'black',
    marginLeft: 15,
    fontSize: 18,
  },
  radioIcon: {
    color: 'black',
  },
  doneButton: {
    backgroundColor: colors.brandColor,
    borderRadius: 5,
    marginTop: 40,
    marginLeft: 80,
    marginRight: 80,
  },
  nextButtonText: {
    fontSize: 18,
    // fontWeight: 'bold',
    color: 'black',
    textAlign: 'center',
  },
};

function PrivacyPolicy() {
  const [region, setRegion] = useState('US');
  const [isModalVisible, setModalVisible] = useState(false);

  // choose content to present
  const legalContent = privacyPolicyUSJSON;

  const showModal = () => {
    // todo: uncomment when adding other region support
    // setModalVisible(true);
    console.log(isModalVisible);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleRegionChange = (value) => {
    setRegion(value);
  };

  const handleNext = () => {
    // Implement your logic to proceed with the selected region
    console.log(`Selected region: ${region}`);
    hideModal();
  };

  // Define options for the region selection modal
  const regionOptions = [
    { key: 'US', label: 'US' },
    { key: 'EEA', label: 'EEA, UK and Switzerland' },
    { key: 'Other', label: 'Other regions' },
  ];

  return (
    <>
      <Modal
        style={styles.modal}
        open={isModalVisible}
        onClose={hideModal}
        aria-labelledby="region"
        aria-describedby="choose-region"
      >
        <Box style={styles.modalContainer}>
          <div style={styles.modalContent}>
            <div style={styles.modalHeader}>
              <Typography style={styles.modalTitle}>Select Region</Typography>
              <Button
                style={styles.closeButton}
                onClick={hideModal}
              >
                <CloseIcon name="close" style={styles.closeIcon} size={24} />
              </Button>
            </div>
            <div style={styles.modalMiddle}>
              {regionOptions.map((option) => (
                <Button
                  key={option.key}
                  onClick={() => handleRegionChange(option.key)}
                  style={styles.regionOption}
                >
                  {region === option.key
                    ? <RadioButtonCheckedIcon style={styles.radioIcon} size={24} />
                    : <RadioButtonUncheckedIcon style={styles.radioIcon} size={24} />}
                  <Typography style={styles.regionOptionText}>{option.label}</Typography>
                </Button>
              ))}
            </div>
            <Button
              style={styles.doneButton}
              onClick={handleNext}
            >
              <Typography style={styles.nextButtonText}>Done</Typography>
            </Button>
          </div>
        </Box>
      </Modal>

      <div style={styles.legalContainer}>
        <TableOfContent contentObject={legalContent} />
        <div style={styles.legalContentContainer}>
          <div style={styles.regionSwitchContainer}>
            <Button
              style={styles.forgotPasswordBtn}
              onClick={showModal}
            >
              <LanguageIcon
                style={styles.globeIcon}
                fontSize="medium"
                color="white"
              />
              <Typography style={styles.forgotPasswordBtnText}>{region}</Typography>
            </Button>
          </div>
          <LegalContent content={legalContent} />
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
