import React from 'react';
import cookiesPolicyUS from '../data/cookiesPolicyUS.json';
import LegalContent from '../components/LegalContent';
import TableOfContent from '../components/TableOfContent';

const colors = {
  primaryLightDarkBackground: '#36393F', // dark theme background color
  brandColor: '#7289DA', // brand color
  secondary: '#FFFFFF', // White
  error: '#ED4245', // red
  text: '#FFFFFF', // White
  mutedText: '#B9BBBE', // muted text color
  textSecondary: '#72767D', // Gray
};

const styles = {
  legalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: colors.primaryLightDarkBackground,
  },
  legalContentContainer: {
    flex: 1, // Allow it to take the remaining width
    padding: '50px', // Add padding as needed
    overflowY: 'auto', // Enable vertical scrolling if needed
  },
};

function CookiesPolicy() {
  const legalContent = cookiesPolicyUS;

  return (
    <div style={styles.legalContainer}>
      <TableOfContent contentObject={legalContent} />
      <div style={styles.legalContentContainer}>
        <LegalContent content={legalContent} />
      </div>
    </div>
  );
}

export default CookiesPolicy;
