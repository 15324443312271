/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import './Profile.css';
import { useParams } from 'react-router';
import { Bookmarks, Create } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../context/app-context';
import Image from '../components/Image';
import ActivityIndicator from '../components/ActivityIndicator';

const tabs = [
  {
    id: 'created',
    label: (
      <div className="tab-label">
        <Create />
        <p>Created</p>
      </div>
    ),
  },
  // {
  //   id: 'liked',
  //   label: (
  //     <div className="tab-label">
  //       <Favorite />
  //       <p>Likes</p>
  //     </div>
  //   ),
  // },
  {
    id: 'saved',
    label: (
      <div className="tab-label">
        <Bookmarks />
        <p>Saved</p>
      </div>
    ),
  },
];

const getProfilePicture = (userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/profile.jpg`
  : `/media/${userSub}/profile.jpg`);
const getPostVideo = (postId, userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/${postId}.mp4`
  : `/media/${userSub}/${postId}.mp4`);
const getPostThumbnail = (postId, userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/${postId}.720.jpg`
  : `/media/${userSub}/${postId}.720.jpg`);

const fallback = '/images/fallbackProfile.png';

function PostThumbnail({ imageSrc, videoSrc, onClick = () => {} }) {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  return (
    <div
      className="post-thumbnail"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="thumbnail-image"
        style={{ backgroundImage: `url(${imageSrc})` }}
      >
        {isHovered ? (
          <video ref={videoRef} src={videoSrc} loop muted autoPlay />
        ) : null}
      </div>
    </div>
  );
}

function Tabs({ tabs: ts, activeTab, setActiveTab }) {
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="tabs-container">
      {ts.map((tab) => (
        <button
          type="button"
          key={tab.id}
          className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => handleTabClick(tab.id)}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}

function PostList({ posts, userName, isLoading }) {
  const navigate = useNavigate();

  const onClick = (postId) => {
    navigate(`/post/${postId}`);
  };
  if (isLoading) {
    return <div className="post-list-loading"><ActivityIndicator /></div>;
  }
  if (posts.length === 0) {
    return (
      <div className="empty-posts-message">
        🎉 Hey there! It seems
        {' '}
        {userName}
        {' '}
        hasn’t shared any adventures yet! 🎉
        <br />
        Stay tuned, amazing content might be on its way! 🌟🚀
      </div>
    );
  }
  return (
    <>
      {posts.map((post) => (
        <PostThumbnail
          key={post.id}
          videoSrc={getPostVideo(post.id, post.userSub)}
          imageSrc={getPostThumbnail(post.id, post.userSub)}
          onClick={() => onClick(post.id)}
        />
      ))}
    </>
  );
}

function Profile() {
  const { userSub } = useParams();
  const { state = {}, dispatch } = useAppState();
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  // const { isAuthenticated } = state?.Auth || {};
  const { info, userSub: loadedSub, created } = state?.Profile || {};
  const {
    userName, bio, fullname, followers, following,
  } = info || {};
  const { posts = [], isLoading } = created || {};

  const onRefresh = useCallback(
    (props) => {
      const { force = false } = props || {};
      if (!userSub) return;
      if (loadedSub === userSub && !force) return;
      dispatch({
        type: 'refresh_profile',
        userSub,
      });
    },
    [userSub],
  );

  useEffect(() => {
    onRefresh();
  }, [userSub]);

  return (
    <div className="profile-main-container">
      <div className="profile-header">
        <Image
          defaultSource={fallback}
          source={{ uri: getProfilePicture(userSub) }}
          className="profile-image"
        />
        <div className="user-info-container">
          <h1 className="profile-username">{userName}</h1>
          <h2 className="profile-name">{fullname}</h2>
        </div>
        <div className="profile-counters">
          <div className="profile-counter">
            <p className="profile-counter-value">{followers}</p>
            <p className="profile-counter-label">Followers</p>
          </div>
          <div className="profile-counter">
            <p className="profile-counter-value">{following}</p>
            <p className="profile-counter-label">Following</p>
          </div>
        </div>

        <p className="profile-bio">{bio}</p>
      </div>
      <div className="posts-container">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'created'
          ? (
            <PostList userName={userName} posts={posts} isLoading={isLoading} />
          ) : (
            <div className="informative-message">
              🌟 Uh-oh! It seems you&apos;ve found
              {' '}
              {userName}
              &apos;s collection of
              {' '}
              {activeTab}
              {' '}
              posts! 🌟
              <br />
              To dive in, grab your phone and check them out in the app! 📱💙
            </div>
          )}
      </div>
    </div>
  );
}

export default Profile;
