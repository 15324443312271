/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import moment from 'moment';
import { API } from 'aws-amplify';
import CancelIcon from '@mui/icons-material/Cancel';
import TagList from './TagList';
import { stringifyDateRange } from './DateRange';
import LocationModal from './LocationsModal';
import Image from './Image';
import { useAppState } from '../context/app-context';
import VirtualizedList from './VirtualizedList';
import graphqlOperation from '../utils/operationWrapper';

const OPTIONS = {
  budget: ['Free', '$', '$$', '$$$'],
  duration: ['Short activity', 'Moderate duration', 'Long activity', 'All-day long activity'],
  activityLevel: ['Low level of effort', 'Moderate level of effort', 'High level of effort', 'Intense level of effort'],
};

function isNumber(num) {
  return typeof num === 'number';
}

export const GET_COMMENTS_QUERY = /* GraphQL */ `
  query listComments(
    $filter: CommentsFilterInput
    $limit: Int
    $nextToken: String
    $keyFilter: KeyFilterInput
  ) {
    listComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      keyFilter: $keyFilter
    ) {
      items {
        userSub
        message
        createdAt
        postId
        userProfile {
          userName
        }
      }
      nextToken
    }
  }
`;

const styles = {
  container: {
    flex: 1,
  },
  headerContainer: {
    backgroundColor: '#2C2F33',
    padding: '.5rem',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
  },
  closeButton: {
    alignSelf: 'flex-end',
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  contentContainer: {},
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginBottom: '.5rem',
  },
  description: {
    fontSize: '1.4rem',
    color: '#FEFEFE',
    marginBottom: '.5rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  commentsTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  commentsContainer: {
    marginBottom: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  commentRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '.5rem',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  commentImage: {
    width: '2rem',
    height: '2rem',
    borderRadius: '1rem',
    marginRight: '.5rem',
  },
  commentUser: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginRight: '.3rem',
  },
  date: {
    fontSize: '1rem',
    color: '#B9BBBE',
  },
  comment: {
    fontSize: '1rem',
    color: '#B9BBBE',
    marginTop: '.25rem',
  },
  profileRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  profileImage: {
    width: '2rem',
    height: '2rem',
    borderRadius: '1rem',
    marginRight: '1rem',
  },
  user: {
    fontSize: '1rem',
    color: '#FFFFFF',
  },
};

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: (d) => `${d}s`,
    ss: '%ds',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
});
const getProfilePicture = (userSub) => (window?.location?.hostname === 'localhost'
  ? `https://sandbox.xpfy.app/media/${userSub}/profile.jpg`
  : `/media/${userSub}/profile.jpg`);

const fallback = '/images/fallbackProfile.png';

function CommentItem({ item }) {
  return (
    <div style={styles.commentsContainer}>
      <Image
        source={{ uri: getProfilePicture(item.userSub) }}
        defaultSource={fallback}
        style={styles.commentImage}
      />
      <div style={styles.column}>
        <div style={styles.row}>
          <p style={styles.commentUser}>{`${item?.userProfile?.userName} `}</p>
          <p style={styles.date}>{moment(item?.createdAt).fromNow()}</p>
        </div>
        <p style={styles.comment}>{item.message}</p>
      </div>
    </div>
  );
}

function PostInfoContainer({ post = {} }) {
  const {
    id: postId,
    startDate,
    endDate,
    isEvent,
    description,
    userSub,
    userProfile,
    highlights = {},
    tags = [],
    locations,
    // distanceToFirst,
  } = post;
  const [comments, setComments] = useState([]);
  // const [isLoading, setLoading] = useState(true);
  const { dispatch, state } = useAppState();
  const { isAuthenticated } = state.Auth;

  const displayTags = useMemo(() => ([
    ...(isEvent ? [stringifyDateRange(startDate, endDate)] : []),
    // ...(distanceToFirst ? [`${distanceToFirst.toFixed(1)} miles away`] : []),
    ...(isNumber(highlights?.budget) ? [OPTIONS.budget[highlights.budget]] : []),
    ...(isNumber(highlights?.duration) ? [OPTIONS.duration[highlights.duration]] : []),
    ...(isNumber(highlights?.activityLevel)
      ? [OPTIONS.activityLevel[highlights.activityLevel]]
      : []
    ),
    ...(tags),
  ]), [startDate, endDate, isEvent, highlights]);

  const loadComments = useCallback(() => {
    setComments([]);
    // setLoading(true);
    API.graphql(graphqlOperation(GET_COMMENTS_QUERY, {
      limit: 5,
      keyFilter: {
        postId: {
          eq: postId,
        },
      },
    }))
      .then((result) => {
        let {
          data: { listComments },
        } = result || {};
        if (!listComments) {
          listComments = { items: [] };
        }
        const { items } = listComments;
        setComments(items);
        // setLoading(false);
      })
      .catch((err) => {
        console.log('GET_COMMENTS_QUERY err: ', err);
      });
  }, [postId, isAuthenticated]);

  useEffect(() => {
    if (postId) {
      loadComments();
    }
  }, [postId, loadComments]);

  const renderItem = useCallback(
    (itemProps) => <CommentItem {...itemProps} />,
    [],
  );

  const EmptyListRenderer = useCallback(
    () => (
      <div style={{ marginLeft: 20 }}>
        <p style={styles.comment}>
          No comments yet, download the app and be the first one!
        </p>
      </div>
    ),
    [],
  );

  return (
    <>
      <div>
        <div
          onClick={() => dispatch({
            type: 'set_field',
            value: -1,
            path: ['Sheets', 'post_info'],
          })}
          className="post-info-close-button"
          style={{ ...styles.headerRow, display: 'none' }}
        >
          <p style={styles.title}> </p>
          <CancelIcon style={styles.title}>X</CancelIcon>
        </div>
        <p style={styles.description}>{description}</p>
        <div style={styles.profileRow}>
          <Image
            source={{ uri: getProfilePicture(userSub) }}
            defaultSource={fallback}
            style={styles.profileImage}
          />
          <p style={styles.user}>{`${userProfile?.userName} `}</p>
        </div>
        <TagList tags={displayTags} />
        {/* <HighlightBox {...highlights} /> */}
        <LocationModal locations={locations} />
      </div>
      <div style={styles.commentRow}>
        <p style={styles.commentsTitle}>Comments</p>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <VirtualizedList
          data={comments}
          ItemRenderer={renderItem}
          itemSize={50}
          keyGetter={(item) => `${item.userSub}-${item.createdAt}`}
          EmptyListRenderer={EmptyListRenderer}
        />
      </div>
    </>
  );
}

export default PostInfoContainer;
