/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo, useState } from 'react';
import { LocationOn } from '@mui/icons-material';
import Marker from './Marker';
import MapView from './MapView';

function LocationModal({ locations }) {
  const [modalVisible, setModalVisible] = useState(false);

  const center = useMemo(() => {
    if (!locations?.length) {
      return {};
    }
    const latitudes = locations.map((location) => location.lat);
    const longitudes = locations.map((location) => location.lon);

    const maxLatitude = Math.max(...latitudes);
    const minLatitude = Math.min(...latitudes);
    const maxLongitude = Math.max(...longitudes);
    const minLongitude = Math.min(...longitudes);

    return {
      lat: (maxLatitude + minLatitude) / 2,
      lng: (maxLongitude + minLongitude) / 2,
    };
  }, [locations]);

  function redirectToGoogleMaps(addresses) {
    if (addresses.length === 0) {
      console.error('No addresses provided.');
      return;
    }

    const formattedAddresses = addresses.map((address) => encodeURIComponent(address));

    let googleMapsURL = 'https://www.google.com/maps/dir/';

    if (formattedAddresses.length === 1) {
      // If only one address, set it as the destination.
      googleMapsURL += `?api=1&destination=${formattedAddresses[0]}`;
    } else {
      // If multiple addresses, set the first as the start and the others as stops.
      googleMapsURL += `${formattedAddresses.join('/')}`;
    }

    window.open(googleMapsURL, '_blank');
  }

  const handleMapPress = useCallback(() => {
    redirectToGoogleMaps(locations.map(({ formattedAddress }) => formattedAddress));
  }, [locations]);

  const MarkersArray = useMemo(() => {
    if (!locations) return [];
    return locations.map((location, index) => (
      <Marker
        key={location.formattedAddress}
        lat={location.lat}
        lng={location.lon}
        title={location.name}
        description={location.formattedAddress}
        pinColor="#5865F2"
        orderNumber={index}
      />
    ));
  }, [locations]);

  if (!locations?.length) return null;
  return (
    <>
      <div onClick={() => setModalVisible(true)} style={styles.locationButton}>
        <LocationOn sx={{ fontSize: '.8rem', color: '#5865F2' }} />
        <p style={styles.locationText}>
          {locations?.length}
          {' '}
          Location
          {locations?.length > 1 ? 's' : ''}
        </p>
      </div>

      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          ...(modalVisible ? {} : { display: 'none' }),
        }}
      >
        {/* <div> */}
        <MapView
          center={center}
          isVisible={modalVisible}
          drawPolylines
          // onRegionChangeComplete={newRegion => setRegion(newRegion)}>
        >
          {MarkersArray}
        </MapView>
        <div style={styles.buttonContainer}>
          <div style={styles.backButton} onClick={() => setModalVisible(false)}>
            <p style={styles.buttonText}>Back</p>
          </div>
          <div style={styles.mapButton} onClick={handleMapPress}>
            <p style={styles.buttonText}>Navigate</p>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    bottom: '2rem',
    paddingRight: '.8rem',
    paddingLeft: '.8rem',
  },
  backButton: {
    // width: 120,
    display: 'flex',
    flex: 1,
    height: '2rem',
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2196F3',
    marginRight: '.8rem',
    marginLeft: '.8rem',
    borderColor: 'black',
    borderWidth: 1,
    maxWidth: '8rem',
  },
  mapButton: {
    display: 'flex',
    flex: 1,
    maxWidth: '8rem',
    height: '2rem',
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#4CAF50',
    marginRight: '.8rem',
    marginLeft: '.8rem',
    borderColor: 'black',
    borderWidth: 1,
    cursor: 'pointer',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  locationButton: {
    marginTop: '.5rem',
    marginLeft: '.5rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  locationText: {
    color: '#5865F2',
    fontSize: '1rem',
    marginLeft: 5,
    fontWeight: 'bold',
  },
};

export default LocationModal;
