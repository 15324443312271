import React from 'react';
import './PhoneFrame.css';

function PhoneFrame({ children }) {
  return (
    <div className="hw-screen-composite justify-center">
      <div className="hw-screen-composite-container">
        <picture
          id="overview-chip-chip-performance-hw-1"
          className="overview-chip-chip-performance-hw hw-overlay loaded"
          data-lazy=""
          data-picture-loaded=""
        >
          <source
            srcSet="/images/frames/chip_performance_hw__6ytcie74ao22_medium_2x.png 2x"
            media="(min-width:0px)"
          />
          <img
            src="/images/frames/chip_performance_hw__6ytcie74ao22_medium_2x.png"
            alt=""
          />
        </picture>
        <noscript>
          <picture className="overview-chip-chip-performance-hw hw-overlay">
            <source
              srcSet="/images/frames/chip_performance_hw__6ytcie74ao22_medium_2x.png 2x"
              media="(min-width:0px)"
            />
            <img
              src="/images/frames/chip_performance_hw__6ytcie74ao22_medium_2x.png"
              alt=""
            />
          </picture>
        </noscript>
        <div className="screen-contents">
          <div className="screen-inner-content">
            <div className="inline-media-component-container performance-video">
              <div
                className="video-wrapper"
                data-video-id="performance-video"
                data-component-list="InlineMediaDefault"
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneFrame;
