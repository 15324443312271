import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
} from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import Home from './pages/Home';
import Post from './pages/Post';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Guidelines from './pages/Guidelines';
import CookiesPolicy from './pages/CookiesPolicy';
import Feedback from './pages/Feedback';
import { withAppContext } from './context/app-context';
import Profile from './pages/Profile';
import FooterComponent from './components/Footer';
import About from './components/About';

Amplify.configure(awsExports);

// const styles = {
//   bmBurgerButton: {
//     position: 'fixed',
//     width: '36px',
//     height: '30px',
//     left: '36px',
//     top: '36px',
//   },
//   bmMenu: {
//     background: '#373a47',
//     fontSize: '1.15em',
//   },
// };

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const closeMenu = () => setIsOpen(false);
  const handleStateChange = (state) => setIsOpen(state.isOpen);

  return (
    <div className={`App ${location?.pathname?.split?.('/')?.join?.(' ') || ''}`}>
      <div className="site-header">

        <Link to="/">
          <img
            src="/android-chrome-192x192.png"
            alt="XPfy"
            className="logo-icon"
          />

        </Link>

        <nav className="nav-links">
          <Link to="/" onClick={closeMenu}>HOME</Link>
          <Link to="/about" onClick={closeMenu}>ABOUT</Link>
          <Link to="/legal/feedback" onClick={closeMenu}>CONTACT</Link>
        </nav>
      </div>

      <Menu
        width="100%"
        isOpen={isOpen}
        onStateChange={handleStateChange}
      >
        <Link to="/" onClick={closeMenu}>HOME</Link>
        <Link to="/legal/feedback" onClick={closeMenu}>CONTACT</Link>
        <Link to="/about" onClick={closeMenu}>ABOUT</Link>
      </Menu>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/legal/privacy/policy" element={<PrivacyPolicy />} />
        <Route path="/legal/terms" element={<TermsAndConditions />} />
        <Route path="/legal/guidelines" element={<Guidelines />} />
        <Route path="/legal/cookies" element={<CookiesPolicy />} />
        <Route path="/legal/feedback" element={<Feedback />} />
        <Route path="/post/:postId" element={<Post />} />
        <Route path="/profile/:userSub" element={<Profile />} />
      </Routes>

      <FooterComponent />
    </div>
  );
}

export default withAppContext(() => (
  <Router>
    <App />
  </Router>
));
