import { generateUUID } from './utils';

export const initialDraft = () => ({
  draftId: generateUUID(),
  media_selected: [],
  description: '',
  locations: [],
  hasMediaChanges: false,
  highlights: {
    budget: undefined,
    duration: undefined,
    activityLevel: undefined,
  },
  tags: [],
  voiceOverDestination: '',
  highlightsOpened: false,
  previewed: false,
  uploadProgress: 0,
  isUploading: false,
  thumbnailsProcessing: false,
  thumbnails: [],
  thumbnailTimestamp: 0,
  isEvent: false,
  startDate: new Date(),
  endDate: new Date(),
});

export const initialState = {
  Feed: {
    isLoadingPosts: true,
    posts: [],
    isEnd: false,
    filters: {},
    lastScore: undefined,
    loadedAuth: undefined,
    muted: true,
  },
  PostView: {
    isLoadingPosts: true,
    posts: [],
    isEnd: false,
    postId: '',
    lastScore: undefined,
  },
  CreatorStudio: {
    drafts: {},
    ...initialDraft(),
  },
  Profile: {
    info: { isLoading: false },
    followers: undefined,
    following: undefined,
    created: { isLoading: false },
    liked: { isLoading: false },
    bookmarks: { isLoading: false },
    userSub: undefined,
    activeTab: 'created',
  },
  Sheets: {
    post_info: -1,
    post_comments: -1,
    post_music: -1,
    post_more: -1,
    share: -1,
  },
  Cache: {
    follows: {},
    followers: {},
    likes: {},
    blocks: {},
    blocked_by: {},
    not_interested: {},
    bookmarks: {},
    visited: {},
    reported: {},
    visited_others: {},
    watched: {},
  },
  IG: {
    access_token: '',
    user_id: '',
    posts: [],
    after: null,
    isLoadingPosts: false,
  },
  TikTok: {
    access_token: '',
    user_id: '',
    posts: [],
    after: null,
    isLoadingPosts: false,
  },
  Geo: {},
  Auth: {
    userSub: '',
    email: '',
    emailVerified: false,
    idToken: '',
    accessToken: '',
    refreshToken: '',
    errorMessage: '',
    errorCode: '',
    isAuthenticated: null,
    isAuthenticating: false,
  },
  Chat: {
    chats: {},
    profiles: {},
    isLoadingChats: false,
    opengraph: {},
  },
  Notifications: {
    all: true,
  },
  forceUpdate: false,
};
