import { API } from 'aws-amplify';
import { ELASTI_QUERY } from './elastiQuery';
import { GET_POSTS_QUERY } from './getFeedPosts';
import graphqlOperation from '../../utils/operationWrapper';

export const GET_PROFILE_INFO_QUERY = /* GraphQL */ `
  query getProfile($userSub: ID!) {
    getProfile(userSub: $userSub) {
      userSub
      bio
      fullname
      followers
      following
      userName
    }
  }
`;

const returnGQL = ({ data }) => Object.values(data)[0];

export const getProfileInfo = (userSub) => API.graphql(
  graphqlOperation(GET_PROFILE_INFO_QUERY, {
    userSub,
  }),
).then(returnGQL);

export const getProfileCreatedPosts = (userSub, lastScore) => API.graphql(
  graphqlOperation(GET_POSTS_QUERY, {
    filters: {
      userSub,
    },
    lastScore,
  }),
).then(returnGQL);

export const getProfileLikedPosts = (id, lastScore) => API.graphql(
  graphqlOperation(ELASTI_QUERY, {
    type: 'getLikedPosts',
    id,
    lastScore,
  }),
).then(returnGQL);

export const getProfileBookmarkedPosts = (id, lastScore) => API.graphql(
  graphqlOperation(ELASTI_QUERY, {
    type: 'getBookmarks',
    id,
    lastScore,
  }),
).then(returnGQL);

// export const getFollowerCount = id =>
//   API.graphql(
//     graphqlOperation(ELASTI_QUERY, {
//       type: 'getFollowerCount',
//       id,
//     }),
//   )
//     .then(returnGQL)
//     .then(({count}) => count);
// export const getFollowingCount = id =>
//   API.graphql(
//     graphqlOperation(ELASTI_QUERY, {
//       type: 'getFollowingCount',
//       id,
//     }),
//   )
//     .then(returnGQL)
//     .then(({count}) => count);
