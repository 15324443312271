/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { Auth } from 'aws-amplify';

const on_auth_response = {
  now: (state) => ({
    ...state,
    Auth: {
      userSub: '',
      email: '',
      emailVerified: false,
      idToken: '',
      accessToken: '',
      refreshToken: '',
      errorMessage: '',
      errorCode: '',
      isAuthenticated: false,
      isAuthenticating: true,
    },
  }),
  on_success: (response, state) => {
    const { signInUserSession, attributes } = response || {};
    const { idToken = {}, accessToken = {}, refreshToken = {} } = signInUserSession || {};
    const { email, email_verified: emailVerified, sub: userSub } = attributes || {};
    const isAuthenticated = !!accessToken?.jwtToken;
    return {
      ...state,
      Auth: {
        userSub,
        email,
        emailVerified,
        idToken: idToken.jwtToken || '',
        accessToken: accessToken.jwtToken || '',
        refreshToken: refreshToken.token || '',
        isAuthenticated,
        isAuthenticating: false,
        errorMessage: '',
        errorCode: '',
      },
    };
  },
  on_fail: (exception, state) => ({
    ...state,
    Auth: {
      userSub: '',
      email: '',
      emailVerified: false,
      idToken: '',
      accessToken: '',
      refreshToken: '',
      errorMessage: exception.message,
      errorCode: exception.code,
      isAuthenticated: false,
      isAuthenticating: false,
    },
  }),
};

export const auth_middlewares = {
  set_auth_error: {
    now: (state, action) => ({
      ...state,
      Auth: {
        ...state.Auth,
        errorMessage: action.message || '',
        errorCode: action.errorCode || '',
      },
    }),
  },
  logout: {
    run: () => Auth.signOut(),
    on_success: (res, state) => ({
      ...state,
      Auth: {
        userSub: '',
        email: '',
        emailVerified: false,
        idToken: '',
        accessToken: '',
        refreshToken: '',
        errorMessage: '',
        errorCode: '',
        isAuthenticated: false,
        isAuthenticating: false,
      },
    }),
    on_fail: (e, state) => {
      console.log(e.message);
      // TODO: I have no clue what we wanna do if logout fails.
      return state;
    },
  },
  authenticate: {
    run: (state, action) => Auth.signIn(action.email, action.password),
    ...on_auth_response,
  },
  auth_on_app_start: {
    run: () => Auth.currentAuthenticatedUser({ bypassCache: false }),
    ...on_auth_response,
  },
};
