/* eslint-disable no-use-before-define */
import React from 'react';

function Marker({ orderNumber = 0, pinColor }) {
  return (
    <div style={styles.markerContainer}>
      <div
        style={{
          ...styles.markerCircle,
          ...(pinColor ? { backgroundColor: pinColor } : {}),
        }}
      >
        <p style={styles.markerText}>{orderNumber + 1}</p>
      </div>
    </div>
  );
}

const styles = {
  markerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  markerCircle: {
    display: 'flex',
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: '#007aff',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  markerText: {
    color: 'white',
    fontWeight: 'bold',
  },
};

export default Marker;
