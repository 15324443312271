/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeOnIcon from '@mui/icons-material/VolumeUp';
import ActivityIndicator from './ActivityIndicator';

const Video = forwardRef(({
  uri,
  paused,
  onProgress = () => {},
  muted,
  onToggleMute = () => console.log('Toggle Behavior is undefined'),
  height,
  width,
  poster,
}, ref) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  // Expose video methods and properties to parent through ref
  useImperativeHandle(ref, () => ({
    get currentTime() {
      return videoRef.current?.currentTime;
    },
    get duration() {
      return videoRef.current?.duration;
    },
    play() {
      videoRef.current?.play();
    },
    pause() {
      videoRef.current?.pause();
    },
  }));

  useEffect(() => {
    setTimeout(() => {
      if (videoRef.current.readyState >= 3) { // HAVE_FUTURE_DATA or better
        setIsLoading(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video && !isLoading) {
      if (paused) {
        video.pause();
      } else {
        video.play();
      }
    }
  }, [paused, isLoading]);

  useEffect(() => {
    const progressUpdate = setInterval(() => {
      if (typeof onProgress === 'function') {
        const video = videoRef.current;
        if (!video || paused) return;
        onProgress({
          currentTime: video.currentTime,
          duration: video.duration,
        });
      }
    }, 66);

    return () => {
      clearInterval(progressUpdate);
    };
  }, [onProgress]);

  if (!uri) return null;

  return (
    <>
      <video
        ref={videoRef}
        muted={muted} // TODO: unmute on interaction
        // autoPlay
        loop
        width={width}
        height={height}
        playsInline
        onLoadedData={() => setIsLoading(false)}
        onTimeUpdate={(event) => onProgress({
          currentTime: event.target.currentTime,
          duration: event.target.duration,
        })} // Very slow interval but leaving it as a fallback in case the interval fails
        style={{ objectFit: 'cover', backgroundColor: '#1c1c1c' }}
      >
        <source type="video/mp4" src={uri} />
        <img src={poster} alt="Your browser does not support the <video> tag" />
      </video>
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          display: isLoading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          zIndex: 1,
          cursor: 'pointer',
        }}
        onClick={onToggleMute}
      >
        {muted ? (
          <VolumeOffIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
        ) : <VolumeOnIcon sx={{ color: 'white', fontSize: '1.5rem' }} />}
      </div>
    </>
  );
});

export default Video;
