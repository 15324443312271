import React from 'react';
import './About.css';

function About() {
  return (
    <div className="main-content-about">
      <header className="hero-header-about">
        <div className="hero-text-about">
          <h1>
            ABOUT XPFY.
          </h1>
          <p>
            At
            {' '}
            {' '}
            <img
              src="/inline-logo.png"
              alt="XPfy Icon"
              className="inline-logo-icon-about"
            />
            {' '}
            we believe in the power of experiences to enrich lives, connect communities,
            and create lasting memories.
          </p>
          <div className="download-buttons-about">
            <a
              href="https://apps.apple.com/us/app/xpfy/id6467978881"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/badges/app-store-badge.svg"
                alt="Download on the App Store"
                className="badge-about"
              />
              {/* <a
                href="https://play.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/badges/google-play-badge.svg"
                  alt="Get it on Google Play"
                  className="badge-about"
                />
              </a> */}
            </a>
          </div>
        </div>
        <div className="hero-image-about">
          {/* <img src="/images/IMG_4890.png" alt="Two friends enjoying night life" /> */}
        </div>
      </header>
      <div className="story-feature">

        <div className="story-feature-header">
          <div className="story-feature-text">
            <h1>OUR STORY.</h1>
            <p>
              XPfy was born out of a simple idea: to make the discovery
              of local and global experiences easy, enjoyable, and accessible for everyone.
              Our founders, passionate about travel and exploration, noticed a gap in
              how people share and discover experiences. They envisioned a platform that
              not only showcases the beauty of hidden gems around the world but also
              fosters a community of like-minded adventurers.
            </p>
          </div>
        </div>
        <div className="story-feature-image">
          <img src="/images/3_phones_shots_so.png" alt="interact engage and add to favorites" />
        </div>
      </div>
      <div className="mission-header-about">
        <div className="mission-image-about">
          <img src="/images/our_mission.png" alt="interact engage and add to favorites" />
        </div>
        <div className="mission-text-about">
          <h1>
            OUR MISSION:
          </h1>
          <p>
            Our mission is simple yet profound - to connect people with experiences
            that inspire, entertain, and create joy. Whether it&apos;s uncovering a secluded
            hiking trail, finding the perfect spot for a romantic dinner, or sharing
            personal stories of adventure, XPfy is your gateway to the world&apos;s most
            unforgettable experiences.
          </p>
        </div>
      </div>
      <div className="offer-feature">
        <div className="offer-feature-text-container">
          <div className="offer-feature-text">
            <h1>WHAT WE OFFER.</h1>
            <p>
              <b>XPfy</b>
              {' '}
              is more than just an app; it&apos;s a community.
              We offer a platform where users can:
            </p>
            <ul>
              <li>
                <b>DISCOVER</b>
                {' '}
                unique experiences, from local activities to global adventures.
              </li>
              <li>
                <b>SHARE</b>
                {' '}
                their own stories and adventures with a supportive community.
              </li>
              <li>
                <b>CONNECT</b>
                {' '}
                with friends and plan new experiences together.
              </li>
              <li>
                <b>ENGAGE</b>
                {' '}
                with creators for insights and inspiration.
              </li>
            </ul>
          </div>
        </div>

        <div className="offer-image">
          <img src="/images/chat_feature.png" alt="offer feature" />
        </div>
      </div>
      <div className="values-feature">
        <div className="values-feature-header">
          <div className="values-feature-text">
            <h1>OUR VALUES.</h1>
            <p>
              At the heart of
              {' '}
              <b>XPfy</b>
              {' '}
              are our core values:
            </p>
            <ul>
              <li>
                <b>COMMUNITY:</b>
                {' '}
                Building a supportive and engaging community is at our core.
              </li>
              <li>
                <b>EXPLORATION:</b>
                {' '}
                Encouraging discovery and adventure in every corner of the world.
              </li>
              <li>
                <b>INCLUSIVITY:</b>
                {' '}
                Welcoming people from all walks of life to share their experiences.
              </li>
              <li>
                <b>INNOVATION:</b>
                {' '}
                Continuously evolving to enhance user experience and engagement.
              </li>
            </ul>
          </div>
        </div>
        <div className="values-feature-header">
          <div className="values-feature-text">
            <h1>THE FUTURE OF XPFY.</h1>
            <p>
              We&apos;re constantly innovating and expanding, aiming to bring even more
              unique experiences to our users. Our journey is just beginning,
              and we&apos;re excited to explore the limitless possibilities with our community.
            </p>
            <p>
              <b>JOIN US:</b>
              {' '}
              Become a part of our journey.
              Discover, share, and create memories.
              Let&apos;s explore the world together!
            </p>
          </div>
        </div>
      </div>
      {/* <div className="offer-feature">
        <div className="offer-feature-text-container">
          <div className="offer-feature-text">
            <h1>THE FUTURE OF XPFY.</h1>
            <p>
              We&apos;re constantly innovating and expanding, aiming to bring even more
              unique experiences to our users. Our journey is just beginning,
              and we&apos;re excited to explore the limitless possibilities with our community.
            </p>
            <p>
              <b>JOIN US:</b>
              {' '}
              Become a part of our journey.
              Discover, share, and create memories.
              Let&apos;s explore the world together!
            </p>
          </div>
        </div>

        <div className="offer-image">
          <img src="/images/chat_feature.png" alt="offer feature" />
        </div>
      </div> */}
    </div>
  );
}

export default About;
