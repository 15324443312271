import React from 'react';
import './TableOfContent.css';

const colors = {
  primaryLightDarkBackground: '#36393F', // dark theme background color
  brandColor: '#7289DA', // brand color
  secondary: '#FFFFFF', // White
  error: '#ED4245', // red
  text: '#FFFFFF', // White
  mutedText: '#B9BBBE', // muted text color
  textSecondary: '#72767D', // Gray
};

const styles = {
  anchorsContainer: {
    width: '250px', // Adjust the width as needed
    backgroundColor: colors.primaryLightDarkBackground,
    overflowY: 'auto', // Enable vertical scrolling if needed
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '50px', // Add padding as needed
  },
  anchorUlContainer: {
    listStyleType: 'none',
  },
  anchorLi: {
    //   margin: 0 15px; /* Add spacing between links */
  },
  anchor: {
    color: '#B9BBBE', /* Set link text color */
    textDecoration: 'none', /* Remove decorations from links */
  },
};

function TableOfContent(params) {
  const { contentObject } = params;

  return (
    <div style={styles.anchorsContainer} className="anchorsContainerMobile">
      {contentObject?.map((item) => (
        <ul
          key={item?.title || item}
          style={styles.anchorUlContainer}
        >
          <li style={styles.anchorLi}>
            <a style={styles.anchor} href={`#${item?.title?.split(' ').join('')}`}>
              {item?.title}
            </a>
          </li>
        </ul>
      ))}
    </div>
  );
}

export default TableOfContent;
