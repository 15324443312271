/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import './Post.css';
import { useParams } from 'react-router';
import PostViewer from '../components/PostViewer';
import { useAppState } from '../context/app-context';
import PostInfoContainer from '../components/PostInfoContainer';
import ActivityIndicator from '../components/ActivityIndicator';

function Post() {
  const params = useParams();
  const { postId } = params;
  const { state = {}, dispatch } = useAppState();
  // const { isAuthenticated } = state?.Auth || {};
  const { posts, isEnd, isLoadingPosts } = state?.PostView || {};
  const { post_info } = state?.Sheets || {};

  const getMorePosts = useCallback(() => {
    if (isEnd || isLoadingPosts) return;
    dispatch({
      type: 'getmore_post',
    });
  }, [dispatch, postId, isEnd, isLoadingPosts]);

  const onRefresh = useCallback(() => {
    dispatch({
      type: 'refresh_post',
      postId,
    });
  }, [dispatch, postId]);

  useEffect(() => {
    console.log(postId, params);
    if (!postId) return;
    onRefresh();
  }, [postId]);

  const [post, setPost] = useState(posts?.[0]);

  useEffect(() => {
    if (!post) setPost(posts?.[0]);
  }, [posts]);

  const onActivePostChange = useCallback((index) => {
    if (!posts?.length || isLoadingPosts) return;
    const newPost = posts?.[index];
    setPost(newPost);
    window?.history?.pushState?.({}, `"${post?.userProfile?.userName || ''}" on XPfy`, post?.id || postId);
  }, [posts]);

  return (
    <div
      className="post-main-container"
    >
      <div
        className="viewer rectangle"
      >
        <PostViewer
          postId={postId}
          getMorePosts={getMorePosts}
          posts={posts}
          isLoadingPosts={isLoadingPosts}
          onRefresh={onRefresh}
          isEnd={isEnd}
          onActivePostChange={onActivePostChange}
        />
      </div>

      <div
        className={`rectangle post-info hide-on-small-screen${post_info === 'open' ? ' post-info-overlay' : ' '}`}
      >
        {isLoadingPosts ? (
          <div className="post-loading-indicator">
            <ActivityIndicator />
          </div>
        ) : <PostInfoContainer post={post} />}
      </div>

    </div>
  );
}

export default Post;
