import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import './Home.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
  Home as HomeIcon,
  Search as SearchIcon,
  PhotoCamera as CameraIcon,
  Tune as TuneIcon,
  AccountCircle as ProfileIcon,
} from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CookieConsent from 'react-cookie-consent';
import AliceCarousel from 'react-alice-carousel';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useAppState } from '../context/app-context';
import PostViewer from '../components/PostViewer';
import PhoneFrame from '../components/PhoneFrame';

function Home() {
  const { state = {}, dispatch } = useAppState();
  // const { isAuthenticated } = state?.Auth || {};
  const { posts, isEnd, isLoadingPosts } = state?.Feed || {};
  const { width } = useWindowDimensions();

  // carucel padding is different for mobile
  let percent = 0.07;
  if (width > 768) percent = 0.15;
  if (width > 480 && width <= 768) percent = 0.04;

  const navigate = useNavigate();

  const onRefresh = useCallback(() => {
    // TODO change to use most liked
    dispatch({
      type: 'refresh_feed',
    });
  }, [dispatch]);

  const customActionBehavior = ({ post, action }) => {
    navigate(`/post/${post.id}#${action}`);
  };

  useEffect(() => {
    // if (isAuthenticated === null) return;
    onRefresh();
  }, []);

  const section = useRef(null);
  const [padding, setPadding] = useState(0);

  const syncState = () => {
    const { current } = section;
    if (current) {
      console.log('current.offsetWidth * percent: ', current.offsetWidth * percent);
      setPadding(current.offsetWidth * percent);
    }
  };

  useEffect(syncState, []);

  // const responsive = {
  //   1024: { items: 1 },
  // };

  const items = [
    <div className="crd10 cardItem" data-value="1">
      <div className="cardContent">
        <div className="crd11">
          <img src="/images/IMG_7020.PNG" alt="saved experiences map" />
        </div>

        <div className="crd12">
          <h2>All saved experiences on your map</h2>
          <p>
            Visualize your saved experiences, all conveniently pinned
            for easy navigation and planning.
          </p>
        </div>
      </div>
    </div>,
    <div className="crd10 cardItem" data-value="2">
      <div className="cardContent">
        <div className="crd11">
          <img src="/images/IMG_7021.PNG" alt="multi location map" />
        </div>

        <div className="crd12">
          <h2>Experiences with multiple destinations</h2>
          <p>
            Pick an adventure and see all its locations on the map.
          </p>
        </div>
      </div>
    </div>,
    <div className="crd10 cardItem" data-value="3">
      <div className="cardContent">
        <div className="crd11">
          <img src="/images/IMG_7022.PNG" alt="map navigation" />
        </div>

        <div className="crd12">
          <h2>Effortless Real-Time Navigation</h2>
          <p>
            XPfy&apos;s integration with Maps guides you along the
            fastest paths to experience destinations.
          </p>
        </div>
      </div>
    </div>,
  ];

  const renderPrevButton = () => <ArrowBackIosNewOutlinedIcon sx={{ color: 'white', fontSize: 30, cursor: 'pointer' }} />;
  const renderNextButton = () => <ArrowForwardIosOutlinedIcon sx={{ color: 'white', fontSize: 30, cursor: 'pointer' }} />;

  return (
    <div className="main-content">
      <header className="hero-header">
        <div className="hero-text">
          <h1>
            DISCOVER THE WORLD AROUND YOU.
          </h1>
          <p>
            <img
              src="/inline-logo.png"
              alt="XPfy Icon"
              className="inline-logo-icon"
            />
            {' '}
            brings to you the closest hidden gems, local activities,
            guided personal stories. Whether it&apos;s a hiking trail, a
            perfect picnic spot, the must try restaurant, or a date night idea,
            it&apos;s all here—all contributed by people like you.
            Find your next adventure and create new memories with your friends.
          </p>
          <div className="download-buttons">
            <a
              href="https://apps.apple.com/us/app/xpfy/id6467978881"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/badges/app-store-badge.svg"
                alt="Download on the App Store"
                className="badge"
              />
              {/* <a
                href="https://play.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/badges/google-play-badge.svg"
                  alt="Get it on Google Play"
                  className="badge"
                />
              </a> */}
            </a>
          </div>
        </div>
        <div className="hero-image">
          <PhoneFrame>
            <div
              style={{
                display: 'flex', flexFlow: 'column', width: '100%', backgroundColor: 'rgba(58, 61, 73, 0.60)',
              }}
            >
              <div style={{ flex: 1 }}>
                <PostViewer
                  posts={posts}
                  isLoadingPosts={isLoadingPosts}
                  onRefresh={onRefresh}
                  isEnd={isEnd}
                  customActionBehavior={customActionBehavior}
                />
              </div>

              <div
                style={{
                  maxHeight: 70,
                  width: '100%',
                  backgroundColor: 'black',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingTop: 10,
                    paddingBottom: 40,
                  }}
                >
                  <HomeIcon sx={{ color: 'white', fontSize: 24 }} />
                  <SearchIcon sx={{ color: 'white', fontSize: 24 }} />
                  <CameraIcon sx={{ color: 'white', fontSize: 24 }} />
                  <TuneIcon sx={{ color: 'white', fontSize: 24 }} />
                  <ProfileIcon sx={{ color: 'white', fontSize: 24 }} />
                </div>
                <div className="bottom-bar" />
              </div>
            </div>
          </PhoneFrame>
        </div>
      </header>
      <div className="post-feature">
        <div className="post-feature-image">
          <img src="/images/3_phones_shots_so.png" alt="interact engage and add to favorites" />
        </div>
        <div className="post-feature-header">
          <div className="post-feature-text">
            <h1>FIND IN-DEPTH DETAILS.</h1>
            <p>Dive into detailed information about any activity.</p>
          </div>
          <div className="post-feature-text">
            <h1>INTERACT, ENGAGE AND ADD TO FAVORITES.</h1>
            <p>
              Easily add experiences to your favorites, like and comment on them,
              and see what others have to say. Follow your favorite creators to keep
              up with their latest adventures.
            </p>
          </div>
        </div>
      </div>
      <div className="cards" ref={section}>
        <h2 className="cards-header">Map features</h2>
        <AliceCarousel
          infinite={false}
          mouseTracking
          items={items}
          paddingRight={padding}
          paddingLeft={padding}
          // paddingLeft={100}
          onResized={syncState}
          // responsive={responsive}
          controlsStrategy="responsive"
          disableDotsControls
          renderPrevButton={renderPrevButton}
          renderNextButton={renderNextButton}
        />
      </div>
      <div className="chats-feature">
        <div className="chat-feature-text-container">
          <div className="chats-feature-text">
            <h1>CONNECT AND PLAN WITH FRIENDS.</h1>
            <p>
              Use the in-app chat to communicate with your friends and plan your next activities.
              <br />
              Engage directly with creators for more insights.
            </p>
          </div>
        </div>

        <div className="chats-image">
          <img src="/images/chat_feature.png" alt="chats feature" />
        </div>
      </div>
      <div className="share-feature">
        <div className="share-feature-image">
          <img src="/images/share_exp.png" alt="interact engage and add to favorites" />
        </div>
        <div className="share-feature-header">
          <div className="share-feature-text">
            <h1>SHARE YOUR DISCOVERIES.</h1>
            <p>
              Whether it&apos;s a weekend getaway, an afternoon hike, or a fresh date idea,
              experiences are always better when shared. Easily share your adventures with
              friends via in-app messages, Instagram, or Apple iMessages.
            </p>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        style={{ background: 'black' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px', fontWeight: 'bold' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      {/* Additional content can go here */}
    </div>
  );
}

export default Home;
